// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface ExperienceDataType{
  name:string
}
export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}
export interface DataLegal{
  value:string,
  label:string,
  id:string,
}
export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  optionsShow:{
    [x: string]: any;
    value:string,
    label:string,
    id:string,
  },
  legalServiceType:{
    value:string,
    label:string,
    id:string,
  },
  servicePresnet:boolean,
  countryData:{
  [x: string]: any;
  id: string;
  type: string;
  attributes: {
    name: string;
  };}
  experienceData:ExperienceDataType[],
  servicesData:{
    id:string,
    legal_service_name: string,
  }
  userdata: {
    first_name: string;
    last_name: string;
    country_code: string;
    jurisdiction: string;
    years_of_experience:string;
    description:string;
  };
  productList: string[];
  showLoader: boolean;
  mobileOpen: boolean;
    open: boolean;
    passwordError1: string,
    oldpassword1:string,
    openDialog: boolean;
    openchangepass: boolean;
    oldchangepassword: boolean;
    changepassword: boolean;
    showPasswordCriteria: boolean;
    errormsg: string;
    client_data: {
      [x: string]: any;
      first_name: string;
      last_name: string;
      email: string;
      country_code: string;
      jurisdiction: string;
      firm: string;
      full_phone_number: string;
      profile_image: {
        url: string
      };
    };
    componentId: string;
    TokenMain: string | undefined;
    loadingImg:boolean;
    userDetails: {data:{attributes:{user_role:string}}};
    navigationlawyer:string;
    error_shown:boolean;
    popUpShow:boolean;
    responseShow:{
      errorss: any;
      success:string,
      error:string},
    DeletePop:boolean,
    formValues: {
      task_name: string
      description: string
      trademark_name: string
      trademark_application_number: string
      deadline: string
      jurisdiction: string
      state: string
      legel_service_type: {
        id: string
          name: string
      },
      
      showResponse: {
        errorss: string; 
        success: string;
      };
      saveDraftTrue:boolean
    },
    isSavedraft:boolean,
    errors: {
      taskname: string,
      trademark: string,
      tradeAppNum: string,
      deadLine: string,
      legalServiceType: string,
      jurisdiction: string,
      description: string,
    },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectApiCallId: string='';
  getExperienceApiCallId:string = "";
  getCountryApiCallId:string="";
  getServiceApiCallId:string = "";
  apiCreateProjectCallId: string = "";
  apiGetProjectbyIDListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showLoader: false,
      // Customizable Area Start
      formValues: {
        task_name: "",
        description: "",
        trademark_name: "",
        trademark_application_number: "",
        deadline: "",
        jurisdiction: "",
        state: "",
        legel_service_type: {
          id: "",
            name: " "
        },
      },

      optionsShow:[],
      legalServiceType:[],
      servicePresnet:false,
      countryData:[],
      servicesData:[],
      experienceData:[],
      productList: [],
      mobileOpen: false,
      open: false,
      passwordError1: "",
      openDialog: false,
      oldpassword1:'',
      openchangepass: false,
      oldchangepassword: false,
      changepassword: false,
      showPasswordCriteria: false,
      errormsg: "",
      client_data: {
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        full_phone_number: "",
        jurisdiction: "",
        firm: "",
        profile_image: {
          url: ''
        }
      },
      componentId: '1',
      TokenMain: "",
      loadingImg:false,
      navigationlawyer:"",
      error_shown:false,
      userDetails: {data:{
      attributes:{
        user_role:""
      }
      }},
      popUpShow:false,
      responseShow:{
        success:"",
        error:""},
      DeletePop:false,
      showResponse:{
        errorss:"",
        success:""
      },
      saveDraftTrue:false,
      errors: {
        taskname: '',
        trademark: '',
        tradeAppNum: '',
        deadLine: '',
        legalServiceType: '',
        jurisdiction: '',
        description: '',
      },
      isSaveDraft:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
     if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({countryData:responseJson?.data})
      
     }
     if (apiRequestCallId === this.getServiceApiCallId) {
      this.setState({servicesData:responseJson, servicePresnet:true})
      if(responseJson){
        let user: DataLegal[] = await Promise.all(
          responseJson.map(async (e:ServicesDataType) => ({
            id: e.id,
            label: e.legal_service_name,
            value: e.legal_service_name,
          }))
        );
          this.setState({optionsShow:user})
      }
     }
     if (apiRequestCallId === this.apiCreateProjectCallId) {  
      if(responseJson?.errors){  
        this.setState({openDialog:true,responseShow:{errorss:responseJson.errors,success:""}})
      }
      else if(responseJson?.meta?.message){
        this.setState({deletePopUp:false,openDialog:true,responseShow:{errorss:"",success:responseJson.meta.message}})
      }
    } 
  }
  

    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCountryList();
   this.getServicesList()
   
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleDraftTrue=()=>{
    this.setState({ isSaveDraft: true })
  }
  handleDraftFalse=()=>{
    this.setState({ isSaveDraft: false })
  }

  handleclosesidebar = () => 
    {this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {this.setState({ open: true })

  };

  handleClose = () => 
    { this.setState({ open: false });

  };

   

  handleSaveDraft = async (values) => {
    await this.submitData(values, true); 

  };
  handleSettingIn = async() => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));
    
    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }
    
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitData = async (formValues, isSavedraft) => {
    const ctokes: string = await getStorageData("clientTokes"); 
       
  
  
    const header = {
      ...(ctokes && { "token": ctokes }) 
    };
    const formData = new FormData();
  
    formData.append("task_name", formValues.taskname);
    formData.append("description", formValues.description);
    formData.append("trademark_name", formValues.trademark);
    formData.append("trademark_application_number", formValues.tradeAppNum);
    formData.append("deadline", formValues.deadLine);
    formData.append("jurisdiction", formValues.jurisdiction);
    formData.append("legel_service_type_id", formValues.legalServiceType);
    if (isSavedraft) {
      formData.append("save_as_draft", "true");
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.apiCreateProjectCallId = requestMessage.messageId;
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_projecttemplates/project_templates"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodPost
    );
  
    // Send the request message
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskList");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }


  // Customizable Area End
}