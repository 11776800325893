import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import  { MultiValue } from 'react-select';
import  { CountryData } from "react-phone-input-2";
export interface CountryDatatype {
    id: string;
    type: string;
    attributes: {
      name: string;
    };
  }
  
  export interface ExperienceDataType{
    name:string
  }
  export interface DataLegal{
    value:string,
    label:string,
    id:string,
  }
  export interface LegalServices{
        id: string,
        legal_service_name: string,
        legel_service_description: string|null,
        created_at: string,
        updated_at: string
    }
  
  export interface ServicesDataType{
    id:string,
    legal_service_name: string,
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  editToggel:boolean;

  changeimage: string,
  openDialog:boolean,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  userdata: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    jurisdiction: string;
    firm: string;
    full_phone_number: string;
    profile_image: {url:File | null};
    legel_service_type:DataLegal[];
    years_of_experience:string;
    accomplishments:string;
    lawyer_status:string;
  };
  lawfirmNameDisabled:boolean ;
  loadData: boolean;
  userDetails: string;
  countryData:CountryDatatype[],
  experienceData:ExperienceDataType[],
  servicesData:ServicesDataType[],
  prevCountry:string,
  showResponse:{
    error:string,
    success:string
  },
  selectedOptions:MultiValue<LegalServices>;
  legal_services:LegalServices[],
  optionsShow:DataLegal[],
  optionsSelected:any,
  servicePresnet:boolean,
  editUserId:string
 
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditCategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserProfileApiCallId:string="";
  getCountryApiCallId:string = "";
  getExperienceApiCallId:string = "";
  getServiceApiCallId:string = "";
  UpdateUserDetailsApiCallId:string ="";
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      apiImage:null,
      errormsg: "",
      editToggel:false,
     changeimage:'',
     savechanges:{firm:false,website:false},
     openDialog:false,
     userMailId:"",
     EmailErrorMsg:false,
     userdata: {
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      full_phone_number: "",
      jurisdiction: "",
      firm: "",
      profile_image:{url:null},
      legel_service_type:[],
      years_of_experience:"",
      accomplishments:"",
      lawyer_status:""
    },
    lawfirmNameDisabled:false,
    loadData:true,
    userDetails: "",
    countryData:[],
    prevCountry:"in",
    showResponse:{
      error:"",
      success:""
    },
    experienceData:[],
    servicesData:[],
    selectedOptions:[],
    legal_services:[],
    servicePresnet:false,
    optionsShow:[],
    optionsSelected:[],
    editUserId:""


      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserProfile()
    this.getCountryList()
    this.checkLawyer()
    this.getExperienceList()
    this.getServicesList()
    const edittoken:string = await getStorageData("edituser")
    this.setState({editUserId:JSON.parse(edittoken)})
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.UpdateUserDetailsApiCallId) {
        this.asyncUpdateUser(responseJson)
      }
      if (apiRequestCallId === this.getCountryApiCallId) {
        this.setState({countryData:responseJson?.data})
       }
       if (apiRequestCallId === this.getExperienceApiCallId) {
        this.setState({experienceData:responseJson})
       }
       if (apiRequestCallId === this.getServiceApiCallId) {
        this.setState({servicesData:responseJson})
        if(responseJson){
          let user: DataLegal[] = await Promise.all(
            responseJson.map(async (e:ServicesDataType) => ({
              id: e.id,
              label: e.legal_service_name,
              value: e.legal_service_name,
            }))
          );
            this.setState({optionsShow:user})
        }
       }
  
      if (apiRequestCallId === this.getUserProfileApiCallId) {
        if (responseJson?.errors) {
          this.setState({openDialog:true,showResponse:{error:responseJson.errors,success:""}})
        }
        else {
          const user = responseJson?.data.find((e: any) => e.id == this.state.editUserId);

          if (user) {
            const datass = user.attributes.associated_lawyer_details.data.attributes;
            console.log("dataaa",datass)
            this.setState({
              userdata: datass,
              loadData: false,
              legal_services: datass?.legel_service_type,
              changeimage: datass?.profile_image?.url,
            });

            const selected = await Promise.all(
              datass?.legel_service_type.map((e: LegalServices) => ({
                id: e.id,
                label: e.legal_service_name,
                value: e.legal_service_name,
              }))
            );
            this.setState({ optionsSelected:selected ,servicePresnet:true});
          }
        }
      }
  
  
    // Customizable Area End
  }

 

  asyncUpdateUser=(responseJson:any)=>{
    if (responseJson?.message) {
      this.setState({ editToggel: !this.state.editToggel ,openDialog:true,showResponse:{error:"",success:responseJson.message}})
    }
    else if(responseJson?.errors){
      this.setState({openDialog:true,showResponse:{error:responseJson.errors[0]?.message,success:""}})
    }
  }
 
 
  handleClosePopUp = () => {
    this.setState({ openDialog: false });
    if(this.state.showResponse.success!=""){
      this.getUserProfile()
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml']; 
  
    if (files && files[0] && allowedTypes.includes(files[0].type)) {
      this.setState({ changeimage: URL.createObjectURL(files[0]), apiImage: files[0] });
    } 
  };

  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AdminLawyerManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
  getUserProfileAsync=(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({openDialog:true,showResponse:{error:responseJson.errors,success:""}})
    }
    else {
      this.setState({userMailId:responseJson?.user?.data?.attributes?.email})
    }
  }
  handleStateChange=(signup:string,login:string)=>{
    this.setState({
      userDetails: signup || login
    })
  }
  handleServicesManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicesManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleLawfirmManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  checkLawyer = () => {
    if (this.state.userDetails=="lawyer") {
      this.setState({ lawfirmNameDisabled: true })
    }
  }
  handleCountryChange(
    phone: string,
    country: CountryData | {} 
  ): string {
    const { prevCountry } = this.state;
    if ('countryCode' in country && country.countryCode !== prevCountry) {
      
      this.setState({ prevCountry: country.countryCode });
      return "+" + country.dialCode; 
    }
    return phone;
  }

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };
    this.handleStateChange(metaSignUpToken?.data?.attributes?.user_role,metaLoginToken?.user_role)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UserEditEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  UpdateUserData = async (values:{
    firstname: string,
    lastname: string,
    country: string,
    contactNumber: string,
    email: string,
    firm:string,
    legel_service_type_id:DataLegal[],
    years_of_experience:string,
    accomplishments:string,
    lawyer_status:string
  }) => {

    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);
    const edittoken:string = await getStorageData("edituser")
    const metaEditToken = JSON.parse(edittoken)

    const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
  const header = {
    "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token,
  };

  const formData = new FormData();
    formData.append("first_name", values.firstname);
    formData.append("last_name", values.lastname);
    formData.append("jurisdiction", values.country);
    formData.append("full_phone_number",values.contactNumber);
    formData.append("email",values.email);
    formData.append("firm",values.firm);
    formData.append("legel_service_type_ids[]",values.legel_service_type_id.map(item => item.id).join(','));
    formData.append("years_of_experience",values.years_of_experience);
    formData.append("accomplishments",values.accomplishments);
    formData.append("lawyer_status", values.lawyer_status.toLowerCase());

    if(this.state.apiImage){
      formData.append("image",this.state.apiImage)
    }
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  this.UpdateUserDetailsApiCallId = requestMessage.messageId;


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_categories/categories/update_lawyerfirm_information?account_id=${metaEditToken}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeAddDetail
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getExperienceList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExperienceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/year_of_experience"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}